export default class DataFetcher {
  constructor({ server }) {
    this.server = server
  }

  async fetchInitialData(datasetConfigs) {
    return this.server.fetchInitialData(datasetConfigs)
  }

  async fetchData(requestConfig) {
    return this.server.fetchData(requestConfig)
  }

  async remove(requestConfig) {
    return this.server.remove(requestConfig)
  }

  async save(requestConfig) {
    return this.server.save(requestConfig)
  }
}
