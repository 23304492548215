import { merge, values, includes, defaultsDeep } from 'lodash-es'
import DATASET_TYPES from '@wix/wix-data-client-common/src/datasetTypes'
import { DETAILS_DATASET_ROLE } from '@wix/wix-data-client-common/src/connection-config/roles'
import modes from '@wix/wix-data-client-common/src/dataset-configuration/readWriteModes'
import defaultDatasetConfiguration from '@wix/wix-data-client-common/src/dataset-configuration/defaults'
import { hasDynamicFilter } from '../filter-resolvers'
import { PRIMARY, REGULAR, UNCONFIGURED } from '../data/sequenceType'

const { WRITE } = modes
const { ROUTER_DATASET } = DATASET_TYPES

const getDatasetSequenceType = ({
  collectionId,
  datasetHasDynamicFilter,
  datasetIsDeferred,
  datasetIsRouter,
  datasetIsWriteOnly,
}) => {
  if (!collectionId) return UNCONFIGURED
  return !datasetHasDynamicFilter &&
    !datasetIsDeferred &&
    !datasetIsRouter &&
    !datasetIsWriteOnly
    ? PRIMARY
    : REGULAR
}

const getStaticDatasetConfig = (datasetConfig, datasetType, connections) => {
  const {
    dataset: {
      readWriteType,
      deferred,
      filter,
      collectionName: collectionId,
    } = {},
  } = datasetConfig
  const datasetIsWriteOnly = readWriteType === WRITE
  const datasetIsMaster = connections.some(
    ({ role }) => role === DETAILS_DATASET_ROLE,
  )
  const datasetIsRouter = datasetType === ROUTER_DATASET
  const datasetIsDeferred =
    Boolean(deferred) &&
    !(datasetIsMaster || datasetIsRouter || datasetIsWriteOnly)

  const datasetHasDynamicFilter = filter && hasDynamicFilter(filter)

  const sequenceType = getDatasetSequenceType({
    collectionId,
    datasetHasDynamicFilter,
    datasetIsDeferred,
    datasetIsRouter,
    datasetIsWriteOnly,
  })

  //TODO: migrate these calculations in redux store, or get rid of redux for dataset settings
  return {
    sequenceType,
    datasetIsWriteOnly,
    datasetIsMaster,
    datasetIsRouter,
    datasetIsDeferred,
    datasetHasDynamicFilter,
  }
}

const mergeRouterDatasetConfig = (routerConfig, controllerConfig) =>
  merge({}, controllerConfig, routerConfig)

const completeControllerConfigs = (controllerConfigs, routerPayload) => {
  //TODO: Split completeControllerConfig. Use new format for dataFetcher instead
  const datasetTypes = values(DATASET_TYPES)

  return controllerConfigs.map(controllerConfig => {
    const { type } = controllerConfig
    if (!includes(datasetTypes, type)) {
      throw new Error(
        `type of controller MUST be one of ${datasetTypes} but is ${type}`,
      )
      //TODO: do not throw an error, only log it
    }
    const { config, connections } = controllerConfig

    const mergedConfig =
      type === ROUTER_DATASET
        ? mergeRouterDatasetConfig(routerPayload.config, config)
        : config

    const datasetConfiguration = defaultsDeep({}, mergedConfig, {
      dataset: defaultDatasetConfiguration,
      datasetStaticConfig: getStaticDatasetConfig(
        mergedConfig,
        type,
        connections,
      ), // TODO: Refactor all config, merge with dataset
    })

    return {
      ...controllerConfig,
      config: datasetConfiguration,
    }
  })
}

export default completeControllerConfigs
