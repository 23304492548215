import { mergeWith } from 'lodash-es'
import { mergeReferences } from './utils'

class TempFesClientImpl {
  constructor(dataProvider) {
    this.dataProvider = dataProvider
  }

  _aggregateData(datasetConfigs, datasetsData) {
    return datasetsData.reduce(
      (response, { items, totalCount, error }, index) => {
        if (error) {
          response.recordsInfoByDataset.push({ error })
          return response
        }
        response.recordsInfoByDataset.push({
          itemIds: items.map(({ _id }) => _id),
          totalCount,
        })
        const collectionId = datasetConfigs[index].collectionId
        response.recordsByCollection[collectionId] = items.reduce(
          (acc, record) => {
            const existingRecord = acc[record._id]
            acc[record._id] = mergeWith(existingRecord, record, mergeReferences)

            return acc
          },
          response.recordsByCollection[collectionId] || {},
        )

        return response
      },
      { recordsInfoByDataset: [], recordsByCollection: {} },
    )
  }

  async fetchInitialData(datasetConfigs) {
    const datasetsData = await Promise.all(
      datasetConfigs.map(
        async ({ collectionId, filter, sort, offset, length, includes }) => {
          try {
            return await this.dataProvider.get({
              collectionId,
              filter,
              sort,
              offset,
              length,
              includes,
            })
          } catch (error) {
            return {
              error: fixWixDataError(error),
            }
          }
        },
      ),
    )

    return this._aggregateData(datasetConfigs, datasetsData)
  }

  async fetchData({ collectionId, filter, sort, offset, length, includes }) {
    return this.dataProvider.get({
      collectionId,
      filter,
      sort,
      offset,
      length,
      includes,
    })
  }

  async remove({ collectionId, recordId }) {
    return this.dataProvider.remove({
      collectionId,
      recordId,
    })
  }

  async save({ collectionId, record, includeReferences }) {
    return this.dataProvider.save({
      collectionId,
      record,
      includeReferences,
    })
  }
}

function fixWixDataError({ message, code }) {
  // WixData lib creates an error with broken prototype and sends it to sentry.
  // This reslts empty errors in sentry without any clues on what did go wrong.
  // A perfect example of what the lib should not to do for following single responsibility principle.
  const error = new Error(message)
  error.code = code

  return error
}

export default TempFesClientImpl
