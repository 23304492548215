export default class Features {
  constructor({ experiments, viewMode }) {
    // centralized experiment conduction is currently supported in live sites only
    // so any experiment will always be false in editor, preview and bolt
    return {
      get fes() {
        return experiments.enabled('specs.wixDataViewer.EnableFES')
      },
      get warmupData() {
        return (
          experiments.enabled('specs.wixDataViewer.UseWarmupData') &&
          viewMode === 'Site'
        )
      },
    }
  }
}
