class WixDataProvider {
  constructor(wixDataProxy, schemaAPI) {
    this.wixDataProxy = wixDataProxy // create wixDataProxy here
    this.schemaAPI = schemaAPI
  }

  async get({ collectionId, filter, sort, offset, length, includes }) {
    return this.wixDataProxy.find(
      collectionId,
      filter,
      sort,
      offset,
      length,
      undefined,
      includes,
    )
  }

  async remove({ collectionId, recordId }) {
    return this.wixDataProxy.remove(collectionId, recordId)
  }

  async save({ collectionId, record, includeReferences }) {
    return this.wixDataProxy.save(collectionId, record, { includeReferences })
  }
}

export default WixDataProvider
